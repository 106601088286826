import { Navigate, Route, Routes } from "react-router-dom";
import { AboutPage, FaqPage, HelpCenterPage, IntelectualPropertyProtectionPage, PrivacyPolicyPage, TermAndConditionPage } from "./pages";

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <Navigate to={'/about'} />
      } />
      <Route path="/about" element={
        <AboutPage />
      } />
      <Route path="/intellectual-property-protection" element={
        <IntelectualPropertyProtectionPage />
      } />
      <Route path="/help-center" element={
        <HelpCenterPage />
      } />
      <Route path="/terms-and-conditions" element={
        <TermAndConditionPage />
      } />
      <Route path="/privacy-policy" element={
        <PrivacyPolicyPage />
      } />
      <Route path="/faq" element={
        <FaqPage />
      } />
    </Routes>
  );
}

export default App;
