import { AboutAside, Card, Content } from "../../components";

const FaqPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">FAQ - Pertanyaan yang Sering Diajukan</h1>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-lg font-bold">Bagaimana Pusproject memastikan keamanan data saya?</h1>
                        <ul>
                            <li>
                                Kami menerapkan langkah-langkah keamanan fisik dan elektronik yang ketat untuk melindungi data Anda. Kami juga memiliki kebijakan akses yang terbatas untuk melindungi informasi sensitif.
                            </li>
                        </ul>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default FaqPage;