import { AboutAside, Card, Content } from "../../components";

const AboutPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">Tentang Pusproject</h1>
                        <p className="mt-2">
                            Pusproject adalah sebuah perusahaan yang berfokus pada penyediaan jasa dan layanan. Dalam upaya untuk terus berkembang dan menyesuaikan diri dengan perkembangan teknologi dan kebutuhan pasar, Kami telah memperluas portofolionya dengan penambahan layanan digital pada tahun 2023. Kami berkomitmen untuk memberikan solusi yang inovatif dan berkualitas kepada pelanggan di berbagai sektor.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Layanan Utama Yang Sudah Tersedia</h1>
                        <ul>
                            <li>
                                <span className="font-bold">Jasa Digital:</span> Pusproject telah memperluas layanannya dengan menyediakan jasa digital yang mencakup berbagai aspek, seperti pemasaran digital, pengembangan situs web dan aplikasi, manajemen media sosial, dan analisis data digital. Layanan ini dirancang untuk membantu klien menghadapi tantangan era digital dan memanfaatkan peluang online dengan lebih baik.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Visi</h1>
                        <p>
                            Menjadi pilihan utama dan sumber terpercaya untuk solusi jasa dan layanan, mendorong inovasi, serta menjadi mitra yang andal bagi pelanggan kami dalam mencapai kesuksesan.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Misi</h1>
                        <ul className="list-decimal mx-4">
                            <li className="mt-1">
                                <span className="font-bold">Kualitas Utama: </span>
                                Kami berkomitmen untuk memberikan layanan berkualitas kepada pelanggan kami, dengan fokus pada kepuasan pelanggan.
                            </li>
                            <li className="mt-1">
                                <span className="font-bold">Transformasi Digital: </span>
                                Kami akan menjadi pemimpin dalam mendukung pelanggan dalam menghadapi perubahan digital dengan solusi yang relevan dan inovatif.
                            </li>
                            <li className="mt-1">
                                <span className="font-bold">Kemitraan Jangka Panjang: </span>
                                Kami akan membangun kemitraan jangka panjang dengan pelanggan kami, berkolaborasi dalam mencapai tujuan bisnis mereka, dan menjadi mitra yang andal.
                            </li>
                            <li className="mt-1">
                                <span className="font-bold">Pengembangan Tim: </span>
                                Kami akan memberikan dukungan maksimal untuk pengembangan keterampilan dan pertumbuhan profesional tim kami, yang merupakan aset berharga perusahaan.
                            </li>
                            <li className="mt-1">
                                <span className="font-bold">Tanggung Jawab Sosial: </span>
                                Kami akan aktif dalam upaya tanggung jawab sosial perusahaan.
                            </li>
                        </ul>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default AboutPage;