import { AboutAside, Card, Content } from "../../components";

const IntelectualPropertyProtectionPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">Perlindungan Hak Kekayaan Intelektual (HKI) - Pusproject</h1>
                        <p className="mt-2">
                            Pusproject menganggap Hak Kekayaan Intelektual (HKI) sebagai aset berharga yang harus dijaga dan dihormati. Kami berkomitmen untuk melindungi hak cipta, merek dagang, dan aspek HKI lainnya yang terkait dengan layanan, produk, dan konten yang kami hasilkan.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Hak Cipta:</h1>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Merek Dagang:</h1>
                        <p>
                            <span className="font-bold">Nama "Pusproject":</span>
                            Nama merek "Pusproject" dan logo terkait. Penggunaan merek dagang ini tanpa izin tertulis adalah melanggar hukum.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Pelanggaran HKI:</h1>
                        <p>
                            Kami akan menindak tegas pelanggaran HKI kami sesuai dengan hukum yang berlaku. Ini dapat mencakup tuntutan hukum dan tindakan lainnya yang diperlukan untuk melindungi hak kami.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Laporan Pelanggaran:</h1>
                        <p>
                            Jika Anda menemukan bahwa hak cipta atau merek dagang kami telah dilanggar, atau jika Anda memiliki pertanyaan terkait dengan HKI kami, silakan hubungi kami melalui (pusproject.studio@gmail.com) untuk melaporkannya atau mendapatkan informasi lebih lanjut.
                        </p>
                        <p className="mt-4">
                            Pusproject berkomitmen untuk menghormati dan melindungi HKI kami dan mengharapkan hal yang sama dari semua pengguna kami. Terima kasih atas pengertian dan kerjasamanya dalam menjaga integritas HKI kami.
                        </p>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default IntelectualPropertyProtectionPage;