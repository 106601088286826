import { AssetLogo } from "../../../assets";

const Navbar = (props) => {
    const mainNavigate = process.env.REACT_APP_MAIN;

    const onNavigate = (path) => {
        window.open(path, '_self');
    }

    return (
        <nav className="bg-white py-3 px-7 shadow-md z-10 flex items-center justify-between relative">
            <div className="cursor-pointer" onClick={() => onNavigate(`${mainNavigate}`)}>
                <AssetLogo width="149" height="29" />
            </div>
        </nav>
    );
}

export default Navbar;