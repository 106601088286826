import { AboutAside, Card, Content } from "../../components";

const TermAndConditionPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">Definisi</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                "Perusahaan" merujuk pada Pusproject.
                            </li>
                            <li>
                                "Pelanggan" merujuk pada individu, perusahaan, atau entitas yang menggunakan layanan Pusproject.
                            </li>
                            <li>
                                "Layanan" merujuk pada semua jasa dan produk yang ditawarkan oleh Perusahaan kepada Pelanggan.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Penerimaan Syarat dan Ketentuan</h1>
                        <p>
                            Dengan mengakses atau menggunakan Layanan kami, Anda secara otomatis setuju untuk terikat oleh Syarat dan Ketentuan ini. Jika Anda tidak setuju dengan Syarat dan Ketentuan ini, harap segera hentikan penggunaan Layanan kami.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Penggunaan Layanan</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Anda setuju untuk menggunakan Layanan kami hanya untuk tujuan yang sah, sesuai dengan hukum yang berlaku.
                            </li>
                            <li>
                                Anda bertanggung jawab atas semua tindakan yang dilakukan dengan menggunakan akun atau akses yang Anda miliki.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Pembayaran</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Pembayaran untuk Layanan akan sesuai dengan ketentuan yang tercantum dalam perjanjian atau kesepakatan tertulis antara Perusahaan dan Pelanggan.
                            </li>
                            <li>
                                Pelanggan harus melakukan pembayaran tepat waktu sesuai dengan ketentuan yang telah disepakati.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Kebijakan Privasi</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Kami akan menjaga kerahasiaan data pribadi Pelanggan sesuai dengan Kebijakan Privasi kami.
                            </li>
                            <li>
                                Pelanggan setuju untuk memberikan informasi yang akurat dan lengkap saat diperlukan.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Pemutusan Layanan</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Perusahaan berhak untuk memutuskan Layanan kepada Pelanggan jika terdapat pelanggaran terhadap Syarat dan Ketentuan ini.
                            </li>
                            <li>
                                Pelanggan dapat memutuskan penggunaan Layanan kami kapan saja dengan memberikan pemberitahuan tertulis kepada Perusahaan.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Penggunaan Properti Intelektual</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Konten yang disediakan oleh Perusahaan dilindungi oleh hak cipta. Penggunaan konten tersebut tanpa izin tertulis adalah melanggar hukum.
                            </li>
                            <li>
                                Hak atas merek dagang dan logo Perusahaan adalah milik Perusahaan dan tidak boleh digunakan tanpa izin tertulis.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Ganti Rugi</h1>
                        <ul className="list-decimal mx-4">
                            <li>
                                Pelanggan setuju untuk mengganti rugi dan membebaskan Perusahaan dari setiap tuntutan atau kerugian yang timbul akibat penggunaan Layanan atau pelanggaran terhadap Syarat dan Ketentuan ini.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Perubahan Syarat dan Ketentuan</h1>
                        <p>
                            Perusahaan berhak untuk mengubah Syarat dan Ketentuan ini tanpa pemberitahuan sebelumnya. Perubahan akan efektif sejak tanggal publikasi di situs web Perusahaan.
                        </p>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default TermAndConditionPage;