import { useNavigate } from "react-router-dom";
import Card from "../card/Card";

const AboutAside = () => {
    const navigation = useNavigate();

    return (
        <div className="min-w-[18rem] w-[18rem] max-w-[18rem]">
            <Card>
                <div>
                    <ul className="mt-2">
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/about')}>
                            <div className="flex items-center justify-between font-medium">
                                Tentang Kami
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/intellectual-property-protection')}>
                            <div className="flex items-center justify-between font-medium">
                                Hak Kekayaan Intelektual
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/help-center')}>
                            <div className="flex items-center justify-between font-medium">
                                Pusat Bantuan
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/terms-and-conditions')}>
                            <div className="flex items-center justify-between font-medium">
                                Syarat dan Ketentuan
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/privacy-policy')}>
                            <div className="flex items-center justify-between font-medium">
                                Kebijakan Privasi
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-100 cursor-pointer" onClick={() => navigation('/faq')}>
                            <div className="flex items-center justify-between font-medium">
                                FAQ
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-500" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    );
}

export default AboutAside;