import { AboutAside, Card, Content } from "../../components";

const HelpCenterPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">Selamat datang di Pusat Bantuan Pusproject</h1>
                        <p className="mt-2">
                            Pusat Bantuan kami dirancang untuk membantu Anda dalam menjelajahi dan memanfaatkan layanan Pusproject dengan lebih baik. Di sini, Anda akan menemukan jawaban atas pertanyaan umum dan panduan langkah demi langkah tentang cara menggunakan layanan kami.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Hubungi Kami</h1>
                        <ul className="list-none mt-2">
                            <li>
                                Alamat Email: <span className="font-bold">pusproject.studio@gmail.com</span>
                            </li>
                            <li>
                                Nomor Telepon: <span className="font-bold">+62 821-1490-9358</span>
                            </li>
                        </ul>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default HelpCenterPage;