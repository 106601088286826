import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const Content = (props) => {
    return (
        <div className="text-[14px] min-w-[64rem]">
            {props.navbar !== false && (<Navbar />)}
            <div className="relative">
                <div className="flex justify-center">
                    <div className={`px-3 min-h-[58vh] min-w-[64rem] w-full md:w-[95%] max-w-[80rem]`}>
                        {props.children}
                    </div>
                </div>
            </div>
            {props.footer !== false && (<Footer />)}
        </div>
    );
}

export default Content;