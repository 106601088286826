import { AboutAside, Card, Content } from "../../components";

const PrivacyPolicyPage = () => {
    return (
        <Content>
            <div className="flex gap-5 mt-5">
                <AboutAside />
                <Card className="grow py-5">
                    <div>
                        <h1 className="text-2xl font-bold">Kebijakan Privasi</h1>
                        <h1 className="text-md font-bold">Terakhir Diperbarui: 21 September 2023</h1>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Pengantar</h1>
                        <p>
                            Kebijakan privasi ini menjelaskan bagaimana Pusproject ("Kami" atau "Perusahaan") mengumpulkan, menggunakan, dan melindungi informasi pribadi yang diberikan oleh pengguna ("Anda" atau "Pengguna") selama penggunaan Layanan kami. Kami sangat menghargai privasi Anda dan berkomitmen untuk melindungi data pribadi Anda sesuai dengan hukum yang berlaku.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Jenis Informasi yang Kami Kumpulkan</h1>
                        <p>
                            Kami dapat mengumpulkan informasi berikut dari Pengguna:
                        </p>
                        <ul className="list-decimal mx-4">
                            <li>
                                Informasi Identifikasi Pribadi, seperti nama, tanggal lahir, jenis kelamin, alamat email, dan nomor telepon.
                            </li>
                            <li>
                                Informasi non-identifikasi, seperti informasi penggunaan Layanan.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Bagaimana Kami Menggunakan Informasi Anda</h1>
                        <p>
                            Kami dapat menggunakan informasi yang kami kumpulkan untuk:
                        </p>
                        <ul className="list-decimal mx-4">
                            <li>
                                Memberikan dan memelihara Layanan kami.
                            </li>
                            <li>
                                Memproses pembayaran dan mengelola akun Pengguna.
                            </li>
                            <li>
                                Mengirimkan informasi penting terkait dengan Layanan.
                            </li>
                            <li>
                                Memahami kebutuhan, preferensi, dan tingkat kepuasan Pengguna.
                            </li>
                            <li>
                                Menyediakan dukungan pelanggan.
                            </li>
                            <li>
                                Melakukan analisis data dan peningkatan Layanan.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Bagaimana Kami Melindungi Informasi Anda</h1>
                        <p>
                            Kami telah mengimplementasikan langkah-langkah keamanan fisik, elektronik, dan prosedural yang sesuai untuk melindungi informasi pribadi Pengguna dari akses, penggunaan, atau pengungkapan yang tidak sah. Meskipun kami berusaha keras untuk melindungi data pribadi Anda, kami tidak dapat menjamin keamanan absolut.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Pengungkapan Informasi kepada Pihak Ketiga</h1>
                        <p>
                            Kami tidak akan menjual, membagikan, atau menyewakan informasi pribadi Pengguna kepada pihak ketiga tanpa izin tertulis, kecuali jika diwajibkan oleh hukum.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Hak Anda terhadap Informasi Pribadi Anda</h1>
                        <p>
                            Pengguna memiliki hak untuk mengakses, memperbarui, atau menghapus informasi pribadi mereka yang kami simpan. Untuk mengajukan permintaan tersebut, silakan hubungi kami melalui kontak yang tersedia di bagian bawah kebijakan ini.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Perubahan Kebijakan Privasi</h1>
                        <p>
                            Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Kami akan memberitahukan Anda tentang perubahan signifikan melalui email atau dengan cara lain yang sesuai. Perubahan tersebut akan berlaku sejak tanggal efektif yang dicantumkan dalam pemberitahuan.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-2xl font-bold">Hubungi Kami</h1>
                        <p>
                            Jika Anda memiliki pertanyaan atau komentar tentang Kebijakan Privasi ini atau kebijakan praktik data kami, silakan hubungi kami melalui:
                        </p>
                        <ul className="list-none mt-2">
                            <li>
                                Alamat Email: <span className="font-bold">pusproject.studio@gmail.com</span>
                            </li>
                            <li>
                                Nomor Telepon: <span className="font-bold">+62 821-1490-9358</span>
                            </li>
                        </ul>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default PrivacyPolicyPage;